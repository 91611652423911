
#dv_container_Escala{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vh;
    margin: 2vh 5vw;

    @media (min-width: 768px) {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;  
    }
}


#hd_Louvor{
    display: flex;
    flex-direction: column;
}






#dv_master_notfound{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
}

#dv_Container_NotFound{
    background-color: rgba(255, 255, 255, 0.4);
    max-width: 40%;
    border-color: #fff;
    border-style: solid;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-width: thin;
    align-self: center;
}

#tx_NotFound{
    font-size: 5vw;
    color: #49705D;
}

@media(max-width: 800px){

    #dv_Container_NotFound{
        max-width: 60%;
    }

    #tx_NotFound{
        font-size: 10vw;
    } 
}
body{
  background: rgba(230,230,230,1);
  font-family: 'Segoe UI', sans-serif;
  font-weight: 100;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  background-color: #ebebeb;
  background-blend-mode: lighten;
  font-family: "segoe ui";
}

 h2, h3, h4, h5, p{
  margin: 0;
  font-weight: 100;
}


h1{
  margin: 0;
  font-weight: 300;
}
#dv_Container_Funcao{
    max-width: 27%;
    background-color: rgba(255, 255, 255, 0.6);
    border-color: #fff;
    border-style: solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
    color: #49705D;
}

#img_Funcao{
    max-width:70%;
    padding: 10px;
    opacity: 0.4;
}

#tx_Ministro_Funcao{
    font-size: clamp(1.8vh, 1vw, 2vw);
    font-weight: bold;
}
#dv_Ministerio_Card{
    background-color: #fff;
    width: 20%;
    overflow: hidden;
    align-self: center;
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#tx_Ministerio_Card{
    font-weight:600;
    color: #7ca17a;
}

@media(max-width: 800px) {
    #dv_Ministerio_Card{
        width: 40%;
    }
}
#dv_Header{
    background-color: #7ca17a;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 5vw;
    position: sticky;
    top:0;
    left: 0;
}

#img_logo_icf{
    max-height: 5vh;
}

.tx_Header{
    font-size: clamp(1em, 3vw, 35px);
}


 @media(max-width: 800px) {
    #dv_Header{
        padding: 1vh 5vw;
    }
}
#dv_TituloCard_Header{
    color: #7ca17a;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vw;
}

#tx_PreTitulo_Header{
    font-weight: 200;
    font-size: clamp(1em, 3vw, 25px);
}

#tx_Titulo_Header{
    font-size: clamp(1em, 3vw, 35px);
    font-weight: bold;  
}

@media(max-width: 800px){
    #tx_Titulo_Header{
        font-size: clamp(1em, 7vw, 35px);
        font-weight: bold;
    }
}